import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { fullDate, monthAndDate } from "../utils/date_format"
import { INLINES } from "@contentful/rich-text-types"
import { BLOCKS } from '@contentful/rich-text-types';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import moment from 'moment';
import 'moment/locale/fr';

export const FRmonthYearFormat = (date) => {
  let formattedDate = moment(date).locale('fr').format('MMM DD YYYY');
  formattedDate = formattedDate.replace(/\./g, '');
  return formattedDate.toUpperCase();
};

// Deduplication function
const deduplicateNews = newsItems => {
  const uniqueNews = new Map();
  newsItems.forEach(item => {
    const uniqueKey = `${item.title}-${item.publishedAt}`;
    if (!uniqueNews.has(uniqueKey)) {
      uniqueNews.set(uniqueKey, item);
    }
  });
  return Array.from(uniqueNews.values());
};

const fullDateInEnglish = (date) => {
  const currentLocale = moment.locale(); 
  moment.locale('en');
  const formattedDate = fullDate(date); 
  moment.locale(currentLocale); 
  return formattedDate;
};

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      const value = node.content[0].value;
      if (value.includes('<br/>')) {
        const newChildren = value.split('<br/>').reduce((prev, current, i) => {
          return [...prev, i > 0 && <br />, current];
        }, []);
        return <p>{newChildren}</p>;
      }
      return <p>{children}</p>;
    },
  },
};
  
const News = props => {
  const { news, related } = props.data
  const deduplicatedRelatedNews = deduplicateNews(related.nodes);
  const filteredRelatedNews = deduplicatedRelatedNews.filter(node => node.id !== news.id);

  // Separate French and non-French news
  const frenchNews = filteredRelatedNews.filter(node => node.frenchText === "French");
  const nonFrenchNews = filteredRelatedNews.filter(node => node.frenchText !== "French").slice(0, 3);

  return (
    <Layout>
      <Seo title={news.title} description={news.excerpt.excerpt} />
      <div className="article_type_section">
        <div className="container">
          <div className="backtopage_link">
            <Link to="/news">
              <span className="back_arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="38.938"
                  height="8.071"
                  viewBox="0 0 38.938 8.071"
                >
                  <g
                    id="right-arrow"
                    transform="translate(1231.781 700.503) rotate(180)"
                  >
                    <path
                      id="Icon_open-arrow-left"
                      data-name="Icon open-arrow-left"
                      d="M3.561,0,0,3,3.561,5.935V3.561h34.1V2.374H3.561Z"
                      transform="translate(1231 699.427) rotate(180)"
                      fill="#bf1116"
                    />
                    <path
                      id="Icon_open-arrow-left_-_Outline"
                      data-name="Icon open-arrow-left - Outline"
                      d="M4.061-1.076v2.95h34.1V4.061H4.061V6.995L-.781,3.008Zm33.1,3.95H3.061v-1.8L.781,3l2.28,1.877V3.061h34.1Z"
                      transform="translate(1231 699.427) rotate(180)"
                      fill="#bf1116"
                    />
                  </g>
                </svg>
              </span>
              <span className="en-only">Back To News Hub</span>
              <span className="fr-only">Retour au Centre de Nouvelles</span>
            </Link>
          </div>
          <div className="main_block">
            <div className="main_left_block">
              <div className="article-fw-page">
                <div className="article_image">
                  <div className="spot_light_red">
                    <p className="en-only">news article</p>       
                    <p className="fr-only">article de presse</p>       
                  </div>
                  <GatsbyImage
                    image={getImage(news.imageHeader)}
                    alt="artical"
                  />
                </div>
                <div className="article-body-content">
                  <div className="common_inner_title">
                    <h1>{news.title}</h1>
                    <h4>{news.frenchOnly}</h4>
                  </div>
                  <p className="rn_date_label">
                    <span className="en-only">by</span> {news.author} <span className="header_bar"> | </span>{" "}
                    <span className="fr-only">
                      {FRmonthYearFormat(news.publishedAt)}
                    </span>
                    <span className="en-only">
                    {fullDateInEnglish(news.publishedAt)}
                    </span>
                  </p>
                  {renderRichText(news.content, options)}
                </div>
              </div>
            </div>
            <div className="main_right_block">
              <div className="left_card_block">
                <div className="commn_title_label">
                  <p className="en-only">related news</p>
                  <p className="fr-only">Nouvelles connexes</p>
                </div>
                

                {deduplicatedRelatedNews
                  .filter(node => node.id !== news.id) // Exclude the current article
  .filter(node => node.frenchText == "French") // Correctly filter out French news
  .map(node => (
                  <div className="artical_card fr-only" key={node.id}>
                    <div className="recent_news_box">
                      <div className="recent_news_img">
                        <Link to={`/news/${node.slug}`} tabindex="0">
                          <GatsbyImage
                            image={getImage(node.image)}
                            alt="blog"
                          />
                        </Link>
                      </div>
                      <div className="recent_news_content">
                        <p className="rn_date_label en-only">
                          {fullDate(node.publishedAt)}
                        </p>
                        <p className="rn_date_label">
                          {FRmonthYearFormat(node.publishedAt)}
                        </p>
                        <h3 className="rn_title_text"><Link to={`/news/${node.slug}`} tabindex="0">{node.title}</Link></h3>
                        <div className="educion_learn_more_btn">
                          <Link to={`/news/${node.slug}`} tabindex="0">
                            <span className="en-only">Read More</span>
                            <span className="fr-only">Lire la Suite</span>
                            <i
                              className="fa fa-caret-right"
                              aria-hidden="true"
                            ></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
  ))}
                
                {deduplicatedRelatedNews
                .filter(node => node.id !== news.id) // Exclude the current article
  .filter(node => node.frenchText != "French").slice(0, 3) // Correctly filter out French news
  .map(node => (
                  <div className="artical_card en-only" key={node.id}>
                    <div className="recent_news_box">
                      <div className="recent_news_img">
                        <Link to={`/news/${node.slug}`} tabindex="0">
                          <GatsbyImage
                            image={getImage(node.image)}
                            alt="blog"
                          />
                        </Link>
                      </div>
                      <div className="recent_news_content">
                        <p className="rn_date_label fr-only">
                          {FRmonthYearFormat(node.publishedAt)}
                        </p>
                        <p className="rn_date_label en-only">
                          {fullDateInEnglish(node.publishedAt)}
                        </p>
                        
                        <Link to={`/news/${node.slug}`} tabindex="0">
                          <h3 className="rn_title_text">{node.title}</h3>
                        </Link>
                        <div className="educion_learn_more_btn">
                          <Link to={`/news/${node.slug}`} tabindex="0">
                            Read More
                            <i
                              className="fa fa-caret-right"
                              aria-hidden="true"
                            ></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              </div>
            </div>
          </div>
          <div className="s9-widget-wrapper"></div>
        </div>
      </div>
    </Layout>
  )
}

export default News

export const news = graphql`
  query ($slug: String!) {
    news: contentfulNews(slug: { eq: $slug }) {
      id
      title
      slug
      publishedAt
      imageHeader {
        gatsbyImageData
      }
      excerpt {
        excerpt
      }
      author
      content {
        raw
      }
    }
    related: allContentfulNews(
      sort: { fields: publishedAt, order: DESC }
      limit: 24
    ) {
      nodes {
        id
        title
        frenchText
        slug
        publishedAt
        image {
          gatsbyImageData
        }
      }
    }
  }
`
